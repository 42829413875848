export default {
  data() {
    return {
      posterIcon: "\ue800",
      videoIcon: "\ue801",
      objectIcon: "\ue802",
      galleryIcon: "\ue803",
      peopleIcon: "\ue804",
      interactiveIcon: "\ue805",
      homeIcon: "\ue806",
      fullscreenIcon: "\ue807",
      backIcon: "\ue808",
      forwardIcon: "\ue809",

      posterIconText: "&#xe800;",
      videoIconText: "&#xe801;",
      objectIconText: "&#xe802;",
      galleryIconText: "&#xe803;",
      peopleIconText: "&#xe804;",
      interactiveIconText: "&#xe805;",
      homeIconText: "&#xe806;",
      fullscreenIconText: "&#xe807;",
      backIconText: "&#xe808;",
      forwardIconText: "&#xe809;",
    };
  },
  methods: {
    icon(val) {
      let icon = this[val + "Icon"] || " "
      if (icon == " ") {
        console.log("Missing", val, "Icon")
      }
      switch (val) {
        case "home": icon = this.homeIcon
          break;
      }
      return icon
    },
    iconText(val) {
      let icon = " "
      switch (val) {
        case "poster": icon = this.posterIconText
          break;
        case "video": icon = this.videoIconText
          break;
        case "object": icon = this.objectIconText
          break;
        case "gallery": icon = this.galleryIconText
          break;
        case "person": icon = this.peopleIconText
          break;
        case "interactive": icon = this.interactiveIconText
          break;
        case "home": icon = this.homeIconText
          break;
        case "fullscreen": icon = this.fullscreenIconText
          break;
        case "back": icon = this.backIconText
          break;
        case "forward": icon = this.forwardIconText
          break;
      }
      return icon
    }
  }
}