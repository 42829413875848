<template>
  <div :class="'base ' + site">
    <ExhibitionNavbar></ExhibitionNavbar>
    <router-view />
  </div>
</template>

<script>
import ExhibitionNavbar from "@/components/exhibition/ExhibitionNavbar.vue";
import iconCodes from '@/mixins/iconCodes'
export default {
  components: {
    ExhibitionNavbar
  },
  mixins: [iconCodes],
  data() {
    return {
      showNav: true,
    };
  },
  mounted() {
    window.addEventListener('resize', this.appHeight)
    this.appHeight()
    console.log("this.query",this.query)
    this.$store.commit("setSearchVars", this.query)
  },
  computed: {
    site() {
      return this.$store.getters.getSite
    },
    query() {
      return window.location.search;
    },
    fullPath() {
      return this.$route.fullPath
    }
  },
  methods: {
    appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', window.innerHeight + "px")
      this.$store.commit("setSize", { width: window.innerWidth, height: window.innerHeight })
    }
  },
  watch: {
    $route(to, from) {
    },
    fullPath(val) {
      this.$store.commit("setSearchVars", val)
    },
    query(val) {
      this.$store.commit("setSearchVars", val)
    }
  }
}
</script>

<style lang="scss">
@import './assets/fonts/exhibition.css';
:root {
  --app-height: 100%;
  --app-width: 768px;
  max-width: var(--app-width);
  margin: 0 auto;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-weight: 400;
}
body {
  font-size: 16px;
  --exhibition: #AB3D45;
  --repression: #CC6632;
  --exploitation: #D7922E;
  --rebellion: #90A19D;
  --redemption: #569CAC;
  --charcoal: #191919;
  --overlay: #191919DD;
  --grey: #888888;
  --white: #E4DDD4;
  --full-white: #FFFFFF;
  --full-black: #000000;
  height: var(--app-height);
  max-width: var(--app-width);
  width: 100vw;
  overflow: hidden;
  background: var(--charcoal) url(@/assets/images/exhibition/background.jpg);
  background-size: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
#app, .base {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-flow: column;
  height: 100%;
  color: var(--white);
}
@font-face {
  font-family: "DinCondensed";
  src: url(assets/fonts/DinCondensedBold.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "DDin";
  src: url(assets/fonts/DDinBold.woff2) format("woff2");
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: "exhibition";
  src: url(assets/fonts/exhibition.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
h1 {
  font-family: "DinCondensed", Roboto, sans-serif;
  font-size: 3.5em;
  text-transform: uppercase;
}
h2 {
  font-family: "DinCondensed", Roboto, sans-serif;
  font-size: 1.75em;
  text-transform: uppercase;
  line-height: 1em
}
p {
  font-family: "DDin", Roboto, sans-serif;
  font-size: 1.05em;
}
button {
  border: none;
  border-radius: 0.2em;
  padding: 5px 7px;
  width: 100%;
  margin: 7px 0;
  transition: 0.1s;
}
button:hover {
  transform: translateY(1px);
  background-color: var(--white);
  filter: drop-shadow(0 0.5em 0.5em var(--charcoal));
}
.repression { background-color: var(--repression); }
.exploitation { background-color: var(--exploitation); }
.rebellion { background-color: var(--rebellion); }
.redemption { background-color: var(--redemption); }
.exhibition { background-color: var(--exhibition);}
</style>