import { createStore } from 'vuex'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export default createStore({
  state: {
    site: "Exhibition",
    content: null,
    loading: false,
    userLocation: null,
    searchVars: "",
    media: null,
    width: 500,
    height: 1000,
    firebasePath: "https://storage.googleapis.com/unshackled-roar.appspot.com/",
    storyFilter: [],
    peopleFilter: [],
    settings: null,
    helpMessage: null,
    contentPath: "CP_Exhibition_Content",
    mediaPath: "CP_Exhibition_Media",
    mediaUrl: null,
    storiesUrl: null,
    peopleUrl: null,
    artistUrl: null,
    songlinesUrl: null,
    locationUrl: null,
    markerUrl: null,
    theme: "repression"
  },
  getters: {
    getSite(state) {
      return state.site
    },
    getTheme(state) {
      return state.theme
    },
    getContentPath(state) {
      return state.contentPath
    },
    getMediaPath(state) {
      return state.mediaPath
    },
    getMediaUrl(state) {
      return state.mediaUrl
    },
    getLocationUrl(state) {
      return state.locationUrl
    },
    getStoriesUrl(state) {
      return state.storiesUrl
    },
    getSonglinesUrl(state) {
      return state.songlinesUrl
    },
    getPeopleUrl(state) {
      return state.peopleUrl
    },
    getArtistUrl(state) {
      return state.artistUrl
    },
    getLoactionUrl(state) {
      return state.loactionUrl
    },
    getMarkerUrl(state) {
      return state.markerUrl
    },
    getHelpMessage(state) {
      return state.helpMessage
    },
    getSettings(state) {
      return state.settings
    },
    getStoryFilter(state) {
      return state.storyFilter
    },
    getPeopleFilter(state) {
      return state.peopleFilter
    },
    getFirebasePath(state) {
      return state.firebasePath
    },
    getWidth(state) {
      return state.width
    },
    getHeight(state) {
      return state.height
    },
    getSearchVars(state) {
      return state.searchVars
    },
    getLoading(state) {
      return state.loading
    },
    getContent(state) {
      return state.content
    },
    getUserLocation(state) {
      return state.userLocation
    },
    getMedia(state) {
      return state.media
    },
  },
  mutations: {
    setSite(state, payload) {
      state.site = payload
    },
    setTheme(state, payload) {
      state.theme = payload
    },
    setContentPath(state, payload) {
      state.contentPath = payload
    },
    setMediaPath(state, payload) {
      state.mediaPath = payload
    },
    setMediaUrl(state, payload) {
      state.mediaUrl = payload
    },
    setStoriesUrl(state, payload) {
      state.storiesUrl = payload
    },
    setSonglinesUrl(state, payload) {
      state.songlinesUrl = payload
    },
    setPeopleUrl(state, payload) {
      state.peopleUrl = payload
    },
    setArtistUrl(state, payload) {
      state.artistUrl = payload
    },
    setLocationUrl(state, payload) {
      state.locationUrl = payload
    },
    setMarkerUrl(state, payload) {
      state.markerUrl = payload
    },
    setHelpMessage(state, payload) {
      state.helpMessage = payload
    },
    setSettings(state, payload) {
      state.settings = payload
    },
    setStoryFilter(state, payload) {
      state.storyFilter = payload
    },
    setPeopleFilter(state, payload) {
      console.log("SET PEOPLE", payload)
      state.peopleFilter = payload
    },
    setSize(state, payload) {
      state.width = payload.width
      state.height = payload.height
    },
    setSearchVars(state, payload) {
      state.searchVars = payload
    },
    setContent(state, payload) {
      state.content = payload
    },
    setMedia(state, payload) {
      console.log("MEDIA UPDATED", payload)
      state.media = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setUserLocation(state, payload) {
      state.userLocation = payload
    }
  },
  actions: {
    setStoryData({ commit, dispatch }, payload) {
      let cont = JSON.parse(JSON.stringify(payload))
      for (var c in cont) {
        console.log("setData")
        dispatch("updateStoryItem", cont[c])
      }
      // commit('setMedia',cont)
    },
    updateStoryItem({ commit, getters }, item) {
      console.log("UPDATE", item, " to ", getters.getContentPath)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      cRef.doc(item.id).set(item);
    },
    updatePeopleItem({ commit, getters }, item) {
      console.log("UPDATE", item)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      cRef.doc(item.id).set(item);
    },
    setData({ commit, dispatch }, payload) {
      let cont = JSON.parse(JSON.stringify(payload))
      for (var c in cont) {
        console.log("setData")
        dispatch("updateMediaItem", cont[c])
      }
      // commit('setMedia',cont)
    },
    updateMediaItem({ commit, getters }, item) {
      console.log("UPDATE", item, getters.getMediaPath)
      let cRef = firebase.firestore().collection(getters.getMediaPath)
      cRef.doc(item.id).set(item);
    },
    updateARMarker({ commit, getters }, payload) {
      console.log("UPDATE", payload)
      let cRef = firebase.firestore().collection(getters.getMediaPath)
      cRef.doc(payload.id).update({ markers: payload.markers });
    },
    getContent({ commit, getters }) {
      commit('setLoading', true)
      console.log("GET CONTENT", getters.getContentPath)
      let cRef = firebase.firestore().collection(getters.getContentPath)
      let cont = []
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            let data = doc.data()
            cont.push(data)
          })
          commit('setContent', cont)
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    },
    getMedia({ commit, getters }, payload) {
      commit('setLoading', true)
      console.log("GET MEDIA", payload)
      let cRef = firebase.firestore().collection(getters.getMediaPath).doc(payload)
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          console.log("MEDIA IS XX", querySnapshot.data())
          commit("setMedia", querySnapshot.data())
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    },
    getSettings({ commit }) {
      commit('setLoading', true)
      let cRef = firebase.firestore().collection("settings")
      let settings = {}
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          console.log("SETTINGS", querySnapshot.doc)
          querySnapshot.forEach(doc => {
            console.log("SETTINGS", doc.data())
            if (doc.data()) {
              settings = doc.data()
            }
          })
          console.log("SETTINGS -- ", settings)
          commit('setSettings', settings)
        } else {
          console.log("QUERY NO DATA")
        }
        commit('setLoading', false)
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading', false)
      })
    },
  },
  modules: {},
})