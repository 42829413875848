import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/homepage',            name: 'homepage',  component: () => import('../views/Homepage.vue') },

  { path: '/',                    name: 'home',      component: () => import('../views/ExhibitionHome.vue') },
  { path: '/themes',              name: 'themes',    component: () => import('../views/ExhibitionThemes.vue') },
  { path: '/themes/repression',   name: 'themesRep', component: () => import('../views/ExhibitionThemes.vue') },
  { path: '/themes/exploitation', name: 'themesExp', component: () => import('../views/ExhibitionThemes.vue') },
  { path: '/themes/rebellion',    name: 'themesReb', component: () => import('../views/ExhibitionThemes.vue') },
  { path: '/themes/redemption',   name: 'themesRed', component: () => import('../views/ExhibitionThemes.vue') },
  { path: '/menu',                name: 'menu',      component: () => import('../views/ExhibitionMenu.vue') },
  { path: '/map',                 name: 'map',       component: () => import('../views/ExhibitionMap.vue') },
  { path: '/about',               name: 'about',     component: () => import('../views/ExhibitionAbout.vue') },
  
  { path: '/m',                   name: 'media',      component: () => import('../views/Media.vue')},
  { path: '/data',                name: 'data',      component: () => import('../views/Data.vue')},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router