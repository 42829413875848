<template>
  <div class="theme-container">
    <div v-for="b in buttons" :class="activeClass(b)">
      <div :class="'theme ' + b.theme + ' ' + currentTheme(b) + isActive(b)" @click="showTab(b)">
        <h2>{{ b.title }}</h2>
      </div>
    </div>
  </div>
  <div class="theme-line" :style="'background-color: var(--'+theme+');'"></div>
</template>

<script>
import iconCodes from "@/mixins/iconCodes";
export default {
  name: "ExhibitionThemeBar",
  mixins: [iconCodes],
  data() {
    return {
      filtered: [],
      active: null,
      buttons: {
        repression: { title: "Repression", theme: "repression", active: false },
        exploitation: { title: "Exploitation", theme: "exploitation", active: false },
        rebellion: { title: "Resistance", theme: "rebellion", active: false },
        redemption: { title: "Redemption", theme: "redemption", active: false }
      }
    };
  },
  mounted() {
    if (this.$route.path.length > 9) {
      let theme = this.$route.path.substr(8).split("?")[0]
      console.log("THEME", theme,this.$route.path)
      this.$store.commit("setTheme", theme)
      this.active = this.buttons[theme]
      this.active.active = true
    }
  },
  computed: {
    theme() {
      return this.$store.getters.getTheme
    }
  },
  methods: {
    showTab(b) {
      if (this.active) {
        this.active.active = false
      }
      b.active = true
      this.active = b
      this.$router.push('/themes/' + b.theme)
      this.$store.commit("setTheme", b.theme)
    },
    currentTheme(b) {
      if (b.active) {
        return this.theme;
      }
      return " inactive-theme";
    },
    isActive(b) {
      if (b.active) {
        return " isActive";
      }
      return " inActive";
    },
    activeClass(b) {
      if (b.active) {
        return "activeTab";
      }
      return "inactiveTab";
    }
  },
  watch: {
    theme(val) {
      //this.showTab(this.buttons[val])
    }
  }
};
</script>

<style lang="scss" scoped>
.theme-container {
  display: flex;
  gap: 4px;
  color: var(--charcoal);
}
.theme-line {
  width: 100%;
  height: 4px;
}
.theme {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5em 0.25em 0;
  margin-bottom: 4px;
  line-height: 1.8em;
  border-radius: 0.5em 0.5em 0 0;
  transition: background-color 0.25s;
  cursor: pointer;
}
.isActive {
  margin-bottom: 0;
  transition: background-color 0s;
}
.inactive-theme:hover {
  background-color: var(--white);
}
.activeTab, .inactiveTab {
  display: flex;
  width: 100%;
  height: 2.5em;
}
@media (max-width: 575px) {
  .inactiveTab {
    width: 40%;
    h2 {
      display: none;
    }
  }
}
</style>