import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'firebase/firestore';
import firebase from 'firebase/compat/app';

const app = createApp(App).use(store).use(router)
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('a-')
}
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('ion-')
}

app.mount('#app')

const firebaseConfig = {
  apiKey: "AIzaSyBRqpAV3zpgL2oCtjhum154AEji5OCeUjA",
  authDomain: "unshackled-roar.firebaseapp.com",
  projectId: "unshackled-roar",
  storageBucket: "unshackled-roar.appspot.com",
  messagingSenderId: "365542769849",
  appId: "1:365542769849:web:088c13cfda4be987788768",
  measurementId: "G-VZPMK8VBKD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);