<template>
  <div v-if="notHome">
    <div class="navbar-menu">
      <img class="navbar-logo" @click="routeTo('Home', '/')" src="../../assets/images/exhibition/exhibition_logo.png">

      <!-- If Menu -->
      <div style="display: flex;">
        <h2 class="navbar-button" @click="routeTo('About', '/about')">ABOUT</h2>
      </div>

      <!-- If Map -->
      <!-- <div style="display: flex;">
        <h2 class="navbar-button" @click="routeTo('Map', '/map')">MAP</h2>
        <h2 class="navbar-button" @click="routeTo('About', '/about')">ABOUT</h2>
      </div> -->

    </div>

    <ExhibitionThemeBar v-if="notMedia"></ExhibitionThemeBar>

    <div v-if="showTitle">
      <div @click="goToThemes" class="theme">
        <h2>BACK TO EXHIBITION THEMES</h2>
      </div>
    </div>
  </div>
</template>

<script>
import ExhibitionThemeBar from "@/components/exhibition/ExhibitionThemeBar.vue";
export default {
  name: "ExhibitionNavbar",
  components: {
    ExhibitionThemeBar,
  },
  data() {
    return {
      showMenu: false,
      pageName: "",
    };
  },
  mounted() {
    this.pageName = this.$route.name;
  },
  computed: {
    page() {
      return this.$route.name;
    },
    notHome() {
      return this.$route.path != "/" && this.$route.path != "/lags" && this.$route.path != "/homepage";
    },
    media() {
      return this.$store.getters.getMedia
    },
    notMedia() {
      return this.$route.path.indexOf("/themes") >= 0 && !this.media;
      // return this.$route.path != "/m";
    },
    showTitle() {
      return this.$route.path.indexOf("/about") >= 0
    },
  },
  methods: {
    routeTo(name, path) {
      this.hideMenu();
      this.$router.push(path);
      this.$store.commit("setMedia", null)
    },
    hideMenu() {
      this.showMenu = false
    },
    goToThemes() {
      this.$router.push("/menu");
    }
  },
  watch: {
    page(val) {
      this.pageName = val;
      this.showMenu = false;
      var query = window.location.search;
      this.$store.commit("setSearchVars", query)
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar-menu {
  display: flex;
  justify-content: space-between;
}
.navbar-logo {
  width: 170px;
  height: 100%;
  padding: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
}
.navbar-button {
  display: flex;
  align-items: center;
  margin: 0.25em 0.5em 0 0;
  color: var(--grey);
  cursor: pointer;
  transition: 0.25s;
}
.navbar-button:hover {
  color: var(--white);
}



.media-container {
  width: 100%;
  min-height: 100vh;
  background: var(--charcoal) url(@/assets/images/exhibition/background.jpg);
  background-size: 100%;
}
.theme {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5em 0.25em 0;
  margin-bottom: 4px;
  line-height: 1.8em;
  border-radius: 0.5em;
  transition: background-color 0.25s;
  cursor: pointer;
  color: var(--charcoal);
  background-color: var(--exhibition);
  height: 2.25em;
}
.theme:hover {
  background-color: var(--white);
}
.theme-line {
  width: 100%;
  height: 4px;
  background-color: var(--exhibition);
}
</style>